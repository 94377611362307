<template>
  <v-container class="grid-schedule__container">
    <ScheduleViewOptions v-if="adminView" />
    <table v-if="allLocations && myEvents && myEvents.length" class="grid-schedule">
      <th class="day"></th>
      <th
        v-for="(location, i) in allLocations"
        :key="`loc-heading-${i}`"
        class="primary white--text"
      >
        <h3>{{ location.name }}</h3>
      </th>

      <tbody v-for="(day) in eventsByDateAndHour" :key="day[0]">
        <tr class="grid-schedule__day-header accent white--text">
          <th :colspan="allLocations.length + 1">{{ formatIsoDate(day[0]) }}</th>
        </tr>
        <tr v-for="(eventGroup, j) in day[1]" :key="`row-${j}`" class="grid-schedule__row">
          <td class="grid-schedule__time primary white--text">{{ militaryToAmPm(`${eventGroup[0]}:00`, false) + '-' + militaryToAmPm(`${eventGroup[0]}:45`) }}</td>
          <td v-for="(location, k) in allLocations" :key="`cell-${j}-${k}`" class="background">
            <p class="event-name" v-for="(event, l) in getEvents(eventGroup[1], location)" :key="`event-${j}-${k}-${l}`">
              {{ event.description }}

              <v-icon v-if="adminView" color="error" class="pa-1" @click="editEvent(event)">
                mdi-pencil
              </v-icon>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <v-progress-circular v-else-if="!eventsInitialized" indeterminate size="80" color="primary"></v-progress-circular>
    <p v-else>It looks like there aren't any relevant events.</p>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import ScheduleViewOptions from '@/components/ScheduleViewOptions'

export default {
  name: 'GridSchedule',

  components: {
    ScheduleViewOptions
  },

  computed: {
    ...mapGetters(['allLocations', 'myEvents', 'userRole', 'eventsInitialized']),
    adminView () {
      return this.userRole === 'admin'
    },
    eventsByDateAndHour () {
      if (!this.myEvents || !this.myEvents.length) return {}
      const events = {}
      this.myEvents.forEach((event) => {
        if (event.start && event.date) {
          const hour = event.start.split(':')[0]
          if (!events[event.date]) events[event.date] = {}
          if (!events[event.date][hour]) events[event.date][hour] = []
          events[event.date][hour].push(event)
        }
      })
      const eventArray = Object.entries(events)
      // Now sort by date
      const sorted = eventArray.sort((a, b) => {
        const dateA = new Date(a[0])
        const dateB = new Date(b[0])
        return dateA - dateB
      })

      // Then, for each day, sort by hour
      sorted.forEach((day) => {
        const dayEvents = day[1]
        const dayEventsArray = Object.entries(dayEvents)
        const sortedDayEvents = dayEventsArray.sort((a, b) => {
          return parseInt(a[0]) - parseInt(b[0])
        })
        day[1] = sortedDayEvents
      })
      console.log('Sorted events', sorted)
      // Note that we're returning an array of arrays, not an object, to retain order
      return sorted
    }
  },

  methods: {
    ...mapActions(['globalFlagsListener']),
    getEvents (eventGroup, location) {
      const events = eventGroup.filter((event) => event.location === location.id)
      return events
    },
    editEvent (event) {
      this.$root.$emit('showEventEditor', event)
    }
  },

  async created () {
    await this.globalFlagsListener()
  },

  mounted () {
    console.log('Locations', this.allLocations)
    console.log('My Events', this.myEvents)
  }
}
</script>

<style lang="scss" scoped>
.grid-schedule {
  width: 100%;
  margin-bottom: 80px;

  &__container {
    max-width: 1920px;

    .v-progress-circular {
      display: block;
      margin: 0 auto;
    }
  }

  &__time {
    font-size: 14px;
    min-width: 100px;
  }

  &__day-header {
    th {
      text-align: left !important;
      padding: 8px;
    }
  }

  th {
    text-align: center;
    border: solid 1px transparent !important;
    font-size: 14px;
    line-height: 1.2em;
    padding: 4px;
  }
  tr {
    td {
      padding: 4px;
      position: relative;

      .event-name {
        position: relative;
        line-height: 1.2em;
        font-size: 12px;
        margin: 0;
      }

      .v-icon {
        position: absolute;
        top: 0;
        right: -24px;
        transform: translate(-50%, -50%);
        display: none;
      }

      &:hover {
        .v-icon {
          display: block;
        }
      }
    }
    td:first-child {
      text-align: right;
    }
    td:not(:first-child) {
      text-align: center;
    }
  }
}
</style>
